import { Injectable } from '@angular/core';
import { UsersService } from './api/users.service';
import { User } from '../_models';
import { BehaviorSubject } from 'rxjs';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { LocalStorageService } from './local-storage.service';
import { ToastService } from '../_services';

import { jwtDecode } from 'jwt-decode';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  private userSubject: BehaviorSubject<User | null>;
  public user$: Observable<User | null>;
  private serverUrl = environment.serverUrl;

  constructor(
    protected localStorage: LocalStorageService,
    protected usersService: UsersService,
    private router: Router,
    private http: HttpClient,
    private toastService: ToastService
  ) {
    const storedUser = this.localStorage.get(environment.localStorageKey) as User;
    this.userSubject = new BehaviorSubject<User | null>(storedUser);
    this.user$ = this.userSubject.asObservable();
  }

  public get user(): User | null {
    return this.userSubject.value;
  }

  public setUser(user: User): void {
    this.localStorage.set(environment.localStorageKey, user);
    this.userSubject.next(user);
  }

  public isAlive() {
    const storedUser = this.localStorage.get(environment.localStorageKey) as User;
    if (!storedUser) {
      return false;
    }
    if (storedUser.hasOwnProperty('token')) {
      if (this.isTokenValid(storedUser.token)) {
        return true;
      }
    }
    return false;
  }

  private isTokenValid(token) {
    const decoded = jwtDecode(token);
    return new Date().getTime() < decoded.exp * 1000;
  }

  //To prevent cyclic dependency
  private ErrorNotification(err): Observable<any> {
    let errMsg = '';
    if (err.error instanceof ErrorEvent) {
      errMsg = err.error.message;
    } else {
      errMsg = `${err.error} - ${err.message}`;
    }
    if (err.message.includes('User not found.') || err.message.includes('Invalid credentials.')) {
      errMsg = 'Invalid email or password';
    } else if (err.message.includes('Microsoft identity not found')) {
      errMsg = 'Microsoft identity not found in Slick+';
    } else if (err.errors && Array.isArray(err.errors)) {
      errMsg = 'Email is invalid';
    }
    this.toastService.queue.next({
      message: errMsg,
      panelClass: 'slick-custom-snackbar-error',
    });
    throw new Error(errMsg);
  }

  rxLogin(email: string, password: string): Observable<User> {
    return this.http
      .post<User>(`${this.serverUrl}v1/auth/login`, { email, password })
      .pipe(catchError((err) => this.ErrorNotification(err)));
  }

  requestForgotPasswordEmail(email: string): Observable<any> {
    return this.http
      .post(`${this.serverUrl}v1/auth/forgot-password`, { email })
      .pipe(catchError((err) => this.ErrorNotification(err)));
  }

  updateForgotPassword(body: { newPassword: string; token: string }) {
    return this.http
      .post(`${this.serverUrl}v1/auth/forgot-password`, body)
      .pipe(catchError((err) => this.ErrorNotification(err)));
  }

  updateResetPassword(body: { newPassword: string; token: string }) {
    return this.http
      .post(`${this.serverUrl}v1/auth/reset-password`, body)
      .pipe(catchError((err) => this.ErrorNotification(err)));
  }

  redirectUser(): void {
    switch (this.user.role) {
      case 'admin':
        this.router.navigate([`/`]);
        break;
      default:
        this.router.navigate([`/authentication`]);
    }
  }

  async authenticate(): Promise<void> {
    const storedUser = this.localStorage.get(environment.localStorageKey) as User | null;
    this.setUser(storedUser);
  }

  logout(logoutMessage?: string, silentLogout = false): void {
    logoutMessage = logoutMessage != '' ? logoutMessage : 'Bye!';
    this.setUser(null);
    this.localStorage.remove(environment.localStorageKey);
    if (!silentLogout) {
      this.router.navigate(['/authentication']);
      this.toastService.queue.next({ message: logoutMessage });
    }
  }

  signup(body): Observable<User> {
    return this.http
      .post<User>(`${this.serverUrl}v1/auth/signup`, body)
      .pipe(catchError((err) => this.ErrorNotification(err)));
  }

  msEntraLogin(code: string): Observable<User> {
    return this.http
      .post<User>(`${this.serverUrl}v1/auth/msentra`, { code })
      .pipe(catchError((err) => this.ErrorNotification(err)));
  }

  tokenLogin(token: string): Observable<User> {
    return this.http
      .post<User>(`${this.serverUrl}v1/auth/tokenlogin`, { token })
      .pipe(catchError((err) => this.ErrorNotification(err)));
  }
}
