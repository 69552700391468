<ng-template #template>
  <div
    class="hidden w-[60%] flex-col justify-end bg-purple-200 bg-[url('~src/assets/img/login/pattern.svg')] bg-cover bg-no-repeat
      pb-[10%] lg:flex lg:bg-[0%_-100%]"
  >
    <div class="mx-auto flex flex-col gap-6 px-10">
      <div class="text-center text-4xl font-bold text-white">Bring Knowledge to Life and Life to Knowledge</div>
      <div class="text-center text-xl text-white">
        At Slick+ we've created our own word to capture what we do. The word is "Beolas" and is inspired by the Irish
        language where 'Beo' means Life and 'Eolas' means Knowledge. Let's bring Beolas to the world of work!
      </div>
    </div>
  </div>
</ng-template>
